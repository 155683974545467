import { Mail } from "@mui/icons-material";
import { Box, Button, MenuItem, Snackbar, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import Alert from "./components/Alert";
import { Select } from "./components/Select";
import Table from "./components/Table";

const useStyles = makeStyles({
  navbar: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    borderBottom: "1px solid #EFEFEF",
    background: "#FFF",
    zIndex: 1,
  },
  status: {
    minWidth: "120px!important",
    backgroundColor: "#EF9A9A!important",
    color: "#B71C1C!important",
    borderRadius: "6px",
  },
  complete: {
    backgroundColor: "#C8E6C9!important",
    color: "#1B5E20!important",
  },
});

const TableActions = ({
  value,
  toggleStatus,
  updateActive,
  sendEmail,
}: any) => {
  const styles = useStyles();
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Button
        className={clsx({
          [styles.status]: true,
          [styles.complete]: value?.status === "1",
        })}
        onClick={() => toggleStatus(value)}
      >
        {value?.status === "1" ? "Completed" : "Pending"}
      </Button>
      <Button
        startIcon={<Mail />}
        title="Ignore"
        onClick={() => updateActive(value)}
      >
        Ignore
      </Button>
      <Button
        startIcon={<Mail />}
        title="Send email"
        onClick={() => sendEmail(value)}
      >
        Send
      </Button>
    </Box>
  );
};

function App() {
  const defaultFilters = {
    product: "",
    photoshop: "",
    isActive: "1",
    status: "",
  };
  const [requests, setRequests] = useState<Array<any>>([]);
  const [open, setOpen] = useState(false);
  const [filters, setFilters] = useState(defaultFilters);
  const [message, setMessage] = useState("");
  const styles = useStyles();
  const getRequests = () => {
    const options = {
      method: "GET",
    };
    const { isActive, product, photoshop, status } = filters;
    const filter = {
      status,
      isActive,
      product, photoshop,
    };
    const query = queryString.stringify(filter);
    fetch(
      `https://api.julieinternational.com/getMailRequests.php?${query}`,
      options
    )
      .then((data) => data.json())
      .then((res) => {
        setRequests(res);
      })
      .catch((e) => {
        console.log(e, "error");
      });
  };

  useEffect(() => {
    getRequests();
  }, [filters]);

  const openSnackbar = (message: string) => {
    setOpen(true);
    setMessage(message);
  };

  const handleSnackbarClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
    setMessage("");
  };

  const requestSentSuccessFully = (id: number) => {
    let allRequests = [...requests];
    const requestIndex = requests.findIndex((request) => request.id === id);
    if (requestIndex !== -1) {
      allRequests[requestIndex].sentCount++;
      allRequests[requestIndex].isSent = true;
    }
    setRequests(allRequests);
    openSnackbar("Email sent successfully");
  };

  const sendEmail = (data: any) => {
    fetch(
      `https://api.julieinternational.com/sendUpdateEmail.php?id=${
        data.id
      }&email=${data.email}&count=${data.sentCount * 1 + 1}`
    )
      .then((data) => data.json())
      .then((res) => {
        if (res && res.id) {
          requestSentSuccessFully(data.id);
        }
      })
      .catch((e) => {
        console.log("error", e);
      });
  };

  const updateActive = (data: any) => {
    fetch(
      `https://api.julieinternational.com/toggleActiveRequest.php?id=${
        data.id
      }&active=${data.isActive === "1" ? 0 : 1}`
    )
      .then((data) => data.json())
      .then((res: any) => {
        if (res && !res.error) {
          const requestIndex = requests.findIndex(
            (request) => request.id === data.id
          );
          const allRequests = [...requests];
          if (requestIndex !== -1) {
            allRequests[requestIndex].isActive = res.isActive;
            setRequests(allRequests);
          }
          getRequests();
          openSnackbar("User Request Updated");
        }
      })
      .catch((e) => {
        console.log(e, "error");
      });
  };

  const toggleStatus = (data: any) => {
    fetch(
      `https://api.julieinternational.com/toggleRequestStatus.php?id=${
        data.id
      }&status=${data.status === "1" ? 0 : 1}`
    )
      .then((data) => data.json())
      .then((res: any) => {
        if (res && !res.error) {
          const requestIndex = requests.findIndex(
            (request) => request.id === data.id
          );
          const allRequests = [...requests];
          if (requestIndex !== -1) {
            allRequests[requestIndex].status = res.status;
            setRequests(allRequests);
          }
          // getRequests();
          openSnackbar("Request Status Updated");
        }
      })
      .catch((e) => {
        console.log(e, "error");
      });
  };

  const columns = [
    {
      id: "id",
      label: `Request ID`,
    },
    {
      id: "createdAt",
      label: "Date",
    },
    {
      id: "dongle",
      label: "Dongle",
    },
    {
      id: "product",
      label: "Product + PhotoShop",
      format: (value: any) => value.product + " " + value.photoshop,
    },
    {
      id: "email",
      label: "Email",
    },
    {
      id: "mobile",
      label: "Mobile",
    },
    {
      id: "sentCount",
      label: "Sent Count",
    },
    {
      id: "sentAt",
      label: "Sent Time",
    },
    {
      id: "active",
      label: "Active",
    },
    {
      id: "actions",
      label: "",
      format: (value: any) => (
        <TableActions
          value={value}
          toggleStatus={toggleStatus}
          updateActive={updateActive}
          sendEmail={sendEmail}
        />
      ),
    },
  ];

  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFilters({ ...filters, [name]: value });
  };

  return (
    <div className="App">
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
      <Box className={styles.navbar}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            p: 2,
          }}
        >
          <Typography>Requests {requests.length}</Typography>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Select
              placeholder={`Status`}
              name="status"
              value={filters.status}
              width={120}
              onChange={handleFilterChange}
              menuItems={[
                <MenuItem value={""} key="complete-status">
                  ‎
                </MenuItem>,
                <MenuItem value="1" key="complete">
                  Complete
                </MenuItem>,
                <MenuItem value="0" key="pending">
                  Pending
                </MenuItem>,
              ]}
            />
            <Select
              placeholder={`Active`}
              name="isActive"
              sx={{ ml: 1, mr: 1 }}
              value={filters.isActive}
              width={120}
              onChange={handleFilterChange}
              menuItems={[
                <MenuItem value={""} key="all-status">
                  ‎
                </MenuItem>,
                <MenuItem value="1" key="active">
                  Active
                </MenuItem>,
                <MenuItem value="0" key="in-active">
                  In-active
                </MenuItem>,
              ]}
            />
            <Select
              placeholder={`Product`}
              name="product"
              sx={{ ml: 1, mr: 1 }}
              value={filters.product}
              width={180}
              onChange={handleFilterChange}
              menuItems={[
                <MenuItem value={""} key="all-product">
                  ‎
                </MenuItem>,
                <MenuItem value="Liberty" key="active">
                  Liberty
                </MenuItem>,
                <MenuItem value="Velocity" key="in-active">
                  Velocity
                </MenuItem>,
              ]}
            />
            <Select
              placeholder={`Photoshop`}
              name="photoshop"
              value={filters.photoshop}
              width={200}
              onChange={handleFilterChange}
              menuItems={[
                <MenuItem value={""} key="-">
                  ‎
                </MenuItem>,
                <MenuItem value="cs" key="active">
                  CS
                </MenuItem>,
                <MenuItem value="cc" key="in-active">
                  CC
                </MenuItem>,
              ]}
            />
          </Box>
        </Box>
      </Box>
      <Box sx={{ pt: "96px" }}>
        <Table rows={requests} columns={columns} />
      </Box>
    </div>
  );
}

export default App;
