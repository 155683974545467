import clsx from "clsx";

import MuiTextField, { TextFieldProps } from "@mui/material/TextField";
import { useStyles } from "./styles";

export const Select = ({
  width = 0,
  className = "",
  SelectProps = {},
  menuItems = [],
  placeholderValue = "",
  noDefaults = false,
  placeholder = placeholderValue,
  labelClass = "",
  ...props
}: any) => {
  const classes = useStyles({ width });
  // console.log("menuItems", menuItems)

  return (
    <MuiTextField
      {...props}
      className={clsx(["SelectInput", classes.SelectInput, className])}
      select
      label={placeholder}
      placeholder={placeholder}
      SelectProps={{
        ...SelectProps,
        style: {
          maxHeight: 200,
        },
        MenuProps: {
          PaperProps: {
            style: {
              maxHeight: 200,
              marginTop: 5,
              width: width,
            },
          },
        },
      }}
    >
      {/* {!noDefaults && <MenuItem value={''} sx={{}}>‎</MenuItem>} */}
      {menuItems}
    </MuiTextField>
  );
};
