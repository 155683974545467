import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import React from "react";
import { useTableStyles } from "./styles";

type TableHeaderProps = {
  name: string;
  columns: Array<any>;
  classes?: any;
  order: any;
  orderBy: any;
  onSortRequest: Function;
};

export const TableHeader = ({
  name,
  columns,
  classes,
  order,
  orderBy,
  onSortRequest,
}: TableHeaderProps) => {
  const sortHandler =
    (property: unknown) => (event: React.MouseEvent<unknown>) => {
      onSortRequest(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {columns.map((column: any, columnIndex) => {
          return (
            <TableCell
              className={classes.headCell}
              key={`table-${name}-${columnIndex}`}
              style={column.headerStyles}
            >
              <TableSortLabel
                active={orderBy === column.sortValue}
                direction={
                  orderBy !== column.sortValue
                    ? order.toLocaleLowerCase()
                    : "asc"
                }
                onClick={sortHandler(column.sortValue)}
              >
                {column.label}
              </TableSortLabel>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

export type DataTableProps = {
  rows: Array<unknown>;
  columns: Array<unknown>;
  order?: string;
  orderBy?: string;
  setOrderByHandler?: Function;
  setOrderHandler?: Function;
  loading?: boolean;
  name?: string;
  renderRows?: Function;
};

const DataTable = ({
  rows,
  columns,
  order = "",
  orderBy = "",
  setOrderByHandler,
  setOrderHandler,
  loading = false,
  name = Date.now().toString(),
  renderRows,
}: DataTableProps) => {
  const styles = useTableStyles();
  const handleSortRequest = (
    event: React.MouseEvent<unknown>,
    property: any
  ) => {
    if (setOrderByHandler && setOrderHandler) {
      const isAsc = orderBy === property && order === "ASC";
      if (orderBy === property && order === "DESC") {
        setOrderHandler("orderDirection", "");
        setOrderByHandler("orderField", "");
      } else {
        setOrderHandler("orderDirection", isAsc ? "DESC" : "ASC");
        setOrderByHandler("orderField", property);
      }
    }
  };
  return (
    <Table>
      <TableHeader
        columns={columns}
        order={order}
        orderBy={orderBy}
        onSortRequest={handleSortRequest}
        name={name}
        classes={styles}
      />
      <TableBody>
        {loading && (
          <TableRow>
            <TableCell colSpan={columns.length}>
              <CircularProgress />
            </TableCell>
          </TableRow>
        )}
        {renderRows
          ? renderRows(columns)
          : rows.map((row: any, rowIndex) => (
              <TableRow key={`${name}-row-${rowIndex}`}>
                {columns.map((column: any, columnIndex) => {
                  const value = row[column.id];
                  return (
                    <TableCell
                      key={`${name}-row-${rowIndex}-column-${columnIndex}`}
                      style={column.rowStyles}
                    >
                      {column.format ? column.format(row) : value}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
      </TableBody>
    </Table>
  );
};

export default DataTable;
